<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      {{ source.name ? 'Edit Source' : 'New Source' }}
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <validation-observer ref="textInput">
        <CustomTextInput
          v-model="tempSource.name"
          class="flex-grow-1 mt-3"
          placeholder="Source name"
          header="Name"
          :maxlength="150"
          :required="true"
        />
        <v-row>
          <v-col cols="12">
            <CustomFileUpload
              v-model="tempSource.imageFile"
              placeholder="Select Preview Image"
              header="Preview Image"
              accept="image/*"
            />
          </v-col>
        </v-row>

        <CustomDropdown
          v-model="tempSource.expirationType"
          header="Data Expiration Type"
          placeholder="Select an option"
          :items="expirationTypes"
        />

        <CustomDatePicker
          v-if="tempSource.expirationType === 'static'"
          :date.sync="tempSource.dataExpirationDate"
          placeholder="Set an Expiration Date"
          header="Expiration Date"
          class="mt--2"
        />
        <CustomTextInput
          v-if="tempSource.expirationType === 'dynamic'"
          v-model="tempSource.dynamicExpiryPeriod"
          class="flex-grow-1 mt-2"
          hint="How many months before data with this source is considered expired"
          header="Dynamic Expiry Period"
          type="number"
        />

        <CustomTextarea
          v-model="tempSource.note"
          placeholder="Note"
          class="flex-grow-1 mt-2"
          header="Note"
          style="height: 150px"
        />
      </validation-observer>

      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          @click="onSave"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDatePicker from "@/sharedComponents/CustomDatePicker";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomTextarea from "@/sharedComponents/CustomTextareaV2";
import datesMixin from "@/utils/datesMixin.js";
import CustomFileUpload from "@/sharedComponents/CustomFileUpload";

export default {
  name: "SourceEdit",
  components: {
    CustomTextarea,
    CustomTextInput,
    CustomDatePicker,
    CustomFileUpload,
    CustomDropdown,
  },
  mixins: [datesMixin],
  props:{
    source:{
      default: () => ({})
    },
  },
  data(){
    return {
      tempSource: {
        name: '',
        expirationType: 'never',
        dataExpirationDate: '',
        dynamicExpiryPeriod: 0,
        note: '',
        image: null,
        imageFile: null,
      },
      expirationTypes: [
        { label: 'Does Not Expire', value: 'never' },
        { label: 'Static Date', value: 'static' },
        { label: 'Dynamic Date', value: 'dynamic' },
      ],
    }
  },
  mounted(){
    this.tempSource = { ...this.source }
    if (this.source.notes) {
      this.tempSource.note = this.source.notes.replaceAll("<br>", "\n");
    }
  },
  methods: {
    async onSave() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      this.$emit('save', {
        name: this.tempSource.name,
        expirationType: this.tempSource.expirationType ? this.tempSource.expirationType : 'static',
        dynamicExpiryPeriod: this.tempSource.dynamicExpiryPeriod,
        dataExpirationDate: this.tempSource.dataExpirationDate
          ? this.getSystemDateFormatForHour(this.tempSource.dataExpirationDate, 12)
          : null,
        notes: this.tempSource.note,
        imageFile: this.tempSource.imageFile,
      });
    }
  },
}
</script>
